import Avatar from 'components/Avatar';
import {UserCard} from 'components/Card';
import {withRouter} from 'react-router';
import React from 'react';
import store from '../../store';
import * as action from '../../store/actions';
import {MdClearAll, MdExitToApp} from 'react-icons/md';
import {Button, ListGroup, ListGroupItem, Nav, Navbar, NavItem, NavLink, Popover, PopoverBody,} from 'reactstrap';
import bn from 'utils/bemnames';

const bem = bn.create('header');

class Header extends React.Component {
    state = {
        isOpenNotificationPopover: false,
        isNotificationConfirmed: false,
        isOpenUserCardPopover: false,
    };

    toggleUserCardPopover = () => {
        this.setState({
            isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
        });
    };

    handleSidebarControlButton = event => {
        event.preventDefault();
        event.stopPropagation();

        document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
    };

    render() {
        return (
            <Navbar light expand className={bem.b('bg-white')}>
                <Nav navbar className="mr-2">
                    <Button outline onClick={this.handleSidebarControlButton}>
                        <MdClearAll size={25}/>
                    </Button>
                </Nav>

                <Nav navbar className={bem.e('nav-right')}>
                    <NavItem>
                        <NavLink id="Popover2">
                            <Avatar
                                onClick={this.toggleUserCardPopover}
                                className="can-click"
                            />
                        </NavLink>
                        <Popover
                            placement="bottom-end"
                            isOpen={this.state.isOpenUserCardPopover}
                            toggle={this.toggleUserCardPopover}
                            target="Popover2"
                            className="p-0 border-0"
                            style={{minWidth: 250}}
                        >
                            <PopoverBody className="p-0 border-light">
                                <UserCard
                                    title="Jane"
                                    subtitle="jane@jane.com"
                                    className="border-light"
                                >
                                    <ListGroup flush>
                                        <ListGroupItem
                                            tag="button"
                                            action
                                            className="border-light"
                                            onClick={() => {
                                                store.dispatch(action.authLogout());
                                                this.props.history.push('/login');
                                                // window.location.reload();
                                            }}
                                        >
                                            <MdExitToApp/> Signout
                                        </ListGroupItem>
                                    </ListGroup>
                                </UserCard>
                            </PopoverBody>
                        </Popover>
                    </NavItem>
                </Nav>
            </Navbar>
        );
    }
}

export default withRouter(Header);
